import React from "react"
import { Helmet } from "react-helmet"

import favicon from "../../images/gatsby-icon.png"
import media from "../../images/icon-1.png"
import config from "react-reveal/globals"

config({ ssrFadeout: true })

export default class Head extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Helmet>
        <title>Cloudegis | Your cloud, our expertise.</title>
        <meta
          name="title"
          content="Cloudegis | Your cloud, our expertise - Simple, fast, reliable"
        />
        <meta
          name="description"
          content="Get the help you deserve to fully embrace the cloud and start improving your time to market while saving money."
        />
        {/*OG - Facebook*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cloudegis.com/" />
        <meta
          property="og:title"
          content="Cloudegis | Your cloud, our expertise - Simple, fast, reliable"
        />
        <meta
          property="og:description"
          content="Get the help you deserve to fully embrace the cloud and start improving your time to market while saving money."
        />
        <meta
          property="og:image"
          content={"https://cloudegis.github.io" + media}
        />
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.cloudegis.com/" />
        <meta
          property="twitter:title"
          content="Cloudegis | Your cloud, our expertise - Simple, fast, reliable"
        />
        <meta
          property="twitter:description"
          content="Get the help you deserve to fully embrace the cloud and start improving your time to market while saving money."
        />
        <meta
          property="twitter:image"
          content={"https://cloudegis.github.io" + media}
        />

        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href={favicon.default} rel="shortcut icon" type="image/x-icon" />
        <link href={favicon.default} rel="apple-touch-icon" />

        <script>
          {`
          !function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);
        `}
        </script>
      </Helmet>
    )
  }
}
