import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

function ContactCard({ className, title, icon, children }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contact: allFile(filter: { sourceInstanceName: { eq: "contact" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 200, quality: 75) {
                    originalName
                    ...GatsbyImageSharpFluid_noBase64
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <StyledBG
          className={className}
          style={{
            maxWidth: "350px",
          }}
          imgStyle={{
            objectFit: "contain",
          }}
          fluid={
            data.contact.edges.find(element => {
              let fname = element.node.childImageSharp.fluid.src
                .split("/")
                .pop()
              return fname === `${icon}.png`
            }).node.childImageSharp.fluid
          }
        />
      )}
    />
  )
}

const StyledBG = styled(Img)`
  max-width: 350px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  &::before,
  &::after {
    opacity: 1 !important;
  }
`
export default ContactCard
