import React from "react"
import logo from "../../images/Logo.svg"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "26px",
    height: "20px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#ff9a00",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#ffffff",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#ff9a00",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
}

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false, showBurger: false }
  }

  isMenuOpen = function (state) {
    return state.isOpen
  }
  handleOnClose = () => {
    this.setState({ menuOpen: false })
  }
  handleOnOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  showBurger = () => {
    this.setState({ showBurger: !(window.innerWidth > 991) })
  }

  componentDidMount() {
    this.showBurger()
    window.addEventListener("resize", this.showBurger)
    document.body.setAttribute("style", "overflow: visible; position: static;")
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.showBurger)
  }

  componentDidUpdate(prevProps, prevState) {
    // If the hamburger menu is open, disable scrolling on the site
    if (prevState.menuOpen !== this.state.menuOpen) {
      if (this.state.menuOpen) {
        // overflow:hidden disables the scrolling on a desktop browser
        // position: fixed is additionally needed for mobile devices
        document.body.setAttribute(
          "style",
          "overflow: hidden; position: fixed;"
        )
      } else {
        document.body.setAttribute(
          "style",
          "overflow: visible; position: static;"
        )
      }
    }
  }
  render() {
    return (
      <div id="outer-container">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          role="banner"
          className="navigation w-nav"
        >
          <div className="navigation-container-full">
            <div className="navigation-side-plain">
              <Link to="/" className="brand w-nav-brand w--current">
                <img src={logo} alt="" className="logo" />
              </Link>
            </div>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <Link
                to="/solutions"
                className="nav-link w-nav-link"
                partiallyActive={true}
                activeStyle={{
                  fontWeight: "bold",
                }}
              >
                Solutions
              </Link>
              <Link
                to="/expertise"
                className="nav-link w-nav-link"
                activeStyle={{
                  fontWeight: "bold",
                }}
              >
                Our Expertise
              </Link>
            </nav>
          </div>
        </div>
        {this.state.showBurger && (
          <Menu
            onOpen={this.handleOnOpen}
            onClose={this.handleOnClose}
            isOpen={this.state.menuOpen}
            right
            styles={styles}
          >
            <nav role="navigation" className="w-nav-menu">
              <Link
                to="/solutions"
                className="nav-link w-nav-link"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
                style={{
                  padding: "5px",
                }}
                partiallyActive={true}
              >
                Solutions
              </Link>
              <Link
                to="/expertise"
                className="nav-link w-nav-link"
                activeStyle={{
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
                style={{
                  padding: "5px",
                }}
              >
                Our Expertise
              </Link>
            </nav>
          </Menu>
        )}
      </div>
    )
  }
}
