import React from "react"
import partner_aws from "../../images/partners/aws_apn.png"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

function Partners({ className }) {
  return (
    <div className={className}>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={1}>
          <h4 className="heading-4 left">Partners</h4>
        </Grid>
        <Grid item xs={6} lg={2}>
          <div className="partners-logo">
            <img src={partner_aws} alt="" />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const StyledPartners = styled(Partners)`
  position: static;
  padding: 10px 3% 10px;
  text-align: center;
`
export default StyledPartners
