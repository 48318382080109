import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import ContactCard from "../ContactCard"

function Contact({ className }) {
  return (
    <div className={className}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <a href="https://join.slack.com/t/cloudegisworkspace/shared_invite/zt-gwoov8p2-TyO5wglc4jbwmO5Raouxaw">
            <ContactCard icon={"slack"} />
          </a>
        </Grid>
        <Grid item xs={12} lg={6}>
          <a href="https://discord.gg/Rf8hgkg">
            <ContactCard icon={"discord"} />
          </a>
        </Grid>
      </Grid>
    </div>
  )
}

const StyledContact = styled(Contact)`
  width: 100%;
  height: 100%;
  padding: 0px 3% 0px;
`

export default StyledContact
