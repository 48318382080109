import React from "react"
import Head from "./layout/Head"
import Navbar from "./layout/Navbar"
import Partners from "./layout/Partners"
import Footer from "./layout/Footer"
import Scripts from "./layout/Scripts"
import { Divider } from "@material-ui/core"

export default function Layout({ children }) {
  return (
    <div className={"root"}>
      <Head />
      <Navbar />
      {children}
      <Divider
        variant={"middle"}
        light
        style={{
          marginTop: "25px",
        }}
      />
      <Partners />
      <Footer />
      <Scripts />
    </div>
  )
}
