import React from "react"
import logo from "../../images/logo_gray.svg"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Contact from "./Contact"

export default class Footer extends React.Component {
  render() {
    return (
      <div
        className="footer-03"
        style={{
          paddingBottom: "20px",
        }}
      >
        <div className="footer-bottom line-blank">
          <Grid
            container
            spacing={1}
            alignItems="stretch"
            justify="space-between"
          >
            <Grid item xs={12} md={3} lg={3}>
              <img src={logo} alt="" className="image-9" />
              <br />
              <a className="footer-link" href="mailto:info@cloudegis.com">
                info@cloudegis.com
              </a>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Link to="/solutions" className="footer-link">
                Solutions
              </Link>
              <Link to="/expertise" className="footer-link">
                Our Expertise
              </Link>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Contact />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
